import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FavouriteIcon from '../icons/FavouriteIcon';

import { useDeviceContext } from '../../context/DeviceContext';

import styles from './FavoriteCount.module.scss';

const FavoriteCount = ({
  onClick = () => {},
  selected,
  className = '',
  count
}) => {
  const [hovered, setHovered] = useState(false);
  const { deviceType } = useDeviceContext();

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  const handleAddHover = () => {
    setHovered(true);
  };
  const handleRemoveHover = () => {
    setHovered(false);
  };

  const hasFavorites = count > 0;
  const iconTheme = selected || hovered ? 'interactionDark' : 'empty';
  const classes = classNames(styles['favorite-count'], className);

  const onMouseEventProps =
    deviceType === 'smartphone'
      ? {}
      : { onMouseEnter: handleAddHover, onMouseLeave: handleRemoveHover };

  return (
    <button
      type="button"
      className={classes}
      {...onMouseEventProps}
      onClick={handleClick}
      data-test="favorite-icon"
      data-testid="favorite-icon"
    >
      {hasFavorites ? <span>{count}</span> : null}
      <FavouriteIcon
        stroke="interactionDark"
        theme={iconTheme}
        width="18"
        opacity="1"
      />
    </button>
  );
};

FavoriteCount.propTypes = {
  selected: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  count: PropTypes.number.isRequired
};

export default FavoriteCount;
