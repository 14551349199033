import PropTypes from 'prop-types';

import { Button, FilterUtils } from '@spotahome/ui-library';
import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import './RemoveFilters.scss';

const RemoveFilters = ({ onFiltersUpdate }) => {
  const { filters } = useFiltersContext();
  const renderRemoveFilterButton = (id, title, defaultValues) => {
    return (
      <Button
        color="select"
        alt
        size="small"
        onClick={() => {
          onFiltersUpdate(defaultValues);
        }}
        className="search-title__clear-filter-button"
      >
        {title}
      </Button>
    );
  };

  const activeFilters = FilterUtils.getActiveFilters(filters);

  const defaultFiltersList = [];

  activeFilters.forEach(element => {
    const { 0: filterTitle } = element;

    const groupedRemoveFilter = FilterUtils.groupFilterCategory(filterTitle);
    const { id } = groupedRemoveFilter;

    const filterAlreadyExists = defaultFiltersList.find(
      defaultFilter => defaultFilter.id === id
    );

    if (!filterAlreadyExists) {
      defaultFiltersList.push(groupedRemoveFilter);
    }
  });

  defaultFiltersList.sort((a, b) =>
    a?.appearanceOrder < b?.appearanceOrder ? -1 : 1
  );

  return defaultFiltersList?.length ? (
    <div className="remove-filters">
      {defaultFiltersList?.map(filter => {
        const { id, title, defaultValues } = filter;
        return renderRemoveFilterButton(id, title, defaultValues);
      })}
    </div>
  ) : (
    ''
  );
};

RemoveFilters.propTypes = {
  onFiltersUpdate: PropTypes.func.isRequired
};

export default RemoveFilters;
