export const PREFILTERS_CONFIG = [
  {
    id: 'wifi',
    filter: 'features[]',
    value: 'wifi',
    iconName: 'wifi'
  },
  {
    id: 'students',
    filter: 'features[]',
    value: 'students',
    iconName: 'perfect_for_students'
  },
  {
    id: 'professionals',
    filter: 'features[]',
    value: 'professionals',
    iconName: 'perfect_for_professionals'
  },
  {
    id: 'noDeposit',
    filter: 'noDeposit',
    value: 1,
    iconName: 'lock_closed'
  },
  {
    id: 'checked',
    filter: 'verified',
    value: 1,
    iconName: 'check_circle_transparent'
  },
  {
    id: 'cheapest',
    filter: 'budget',
    value: '0-700',
    iconName: 'happy_face'
  }
];

export const PREFILTERS_CONFIG_ORDER_BY_RELEVANCE_VP = [
  {
    id: 'checked',
    filter: 'verified',
    value: 1,
    iconName: 'check_circle_transparent'
  },
  {
    id: 'noDeposit',
    filter: 'noDeposit',
    value: 1,
    iconName: 'lock_closed'
  },
  {
    id: 'students',
    filter: 'features[]',
    value: 'students',
    iconName: 'perfect_for_students'
  },
  {
    id: 'professionals',
    filter: 'features[]',
    value: 'professionals',
    iconName: 'perfect_for_professionals'
  },
  {
    id: 'wifi',
    filter: 'features[]',
    value: 'wifi',
    iconName: 'wifi'
  },
  {
    id: 'cheapest',
    filter: 'budget',
    value: '0-700',
    iconName: 'happy_face'
  }
];

export default {
  PREFILTERS_CONFIG,
  PREFILTERS_CONFIG_ORDER_BY_RELEVANCE_VP
};
