import { trans } from '@spotahome/soyuz/client';

import { capitalizeFirstLetter } from './stringUtils';

export const SHORT_TERM = 'shortTerm';
export const MID_TERM = 'midTerm';
export const LONG_TERM = 'longTerm';

export const getRentalTypeRanges = minimumStaying => {
  const minRange = Math.max(1, Math.floor(minimumStaying / 30));

  return {
    [SHORT_TERM]: {
      from: minRange,
      to: 6
    },
    [MID_TERM]: {
      from: 6,
      to: 12
    },
    [LONG_TERM]: {
      from: 12,
      to: 24
    }
  };
};

// TODO: This function currently returns the concatenated rental types: Short/Mid/Long
// it should return a range of months based on @search/utils/rentalType, likeL
// when rental types is shortTerm & midTerm, return: 1 - 12 months
export const getRentalTypesDisplay = rentalTypes =>
  rentalTypes
    .map(rentalType =>
      capitalizeFirstLetter(trans(`rentalDurationType.${rentalType}`))
    )
    .join(' / ');
