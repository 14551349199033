import gql from 'graphql-tag';

const QUERY_HOMECARDS = gql`
  query Homecards($ids: [Int], $locale: String = "en") {
    homecards(homecardIds: $ids, locale: $locale) {
      checked
      currencyIsoCode
      firstAvailableDate
      id
      isInstantBooking
      noSecurityDeposit
      isFavorite
      mainPhotoUrl

      reviews {
        ratingAverage
      }
      title
      description
      type
      url
      features
      area
      offers {
        discountType
        discountValue
      }
      allowsPermanentContract
      numberOfBathrooms
      isBestChoice
      landlordId
      so
      photoIds
      otherPropertyPhotoIds
      displayPrice
      billsIncluded
      favoritesCount
    }
  }
`;

const getHomecardsQuery = client => (ids, locale) =>
  client.query({
    fetchPolicy: 'network-only',
    query: QUERY_HOMECARDS,
    variables: { ids, locale }
  });

export default getHomecardsQuery;
