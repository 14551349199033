import { useState } from 'react';
import PropTypes from 'prop-types';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import debounce from '@spotahome/marketplace-common/src/utils/debounce';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import {
  isFilterChecked,
  getEnabledValue,
  getDisabledValue,
  mustShowPrefilters
} from './utils/prefilters';

import {
  PREFILTERS_CONFIG,
  PREFILTERS_CONFIG_ORDER_BY_RELEVANCE_VP
} from './constants';

import IconPreFilterItem from './IconPrefilterItem';

import './PreFilters.scss';
import { useSoyuzExperiment } from '@spotahome/soyuz/client';

const SCROLL_DEBOUNCE = 500;

const PreFilters = ({ onFiltersUpdate }) => {
  const [prefiltersApplied, setPrefiltersApplied] = useState([]);
  const { filters } = useFiltersContext();
  const orderByRelevanceVPexperiment = useSoyuzExperiment(
    'OrderPrefilterByRelevanceOfVP'
  );

  const handlePrefilterClick = filterConfig => () => {
    let newFilterValue;
    let filtersSelected = [...prefiltersApplied];
    if (isFilterChecked(filters, filterConfig.filter, filterConfig.value)) {
      SoyuzAnalytics.sendGA4Event('prefilter-remove', {
        method: filterConfig.id
      });
      newFilterValue = getDisabledValue(
        filters,
        filterConfig.filter,
        filterConfig.value
      );
      filtersSelected = filtersSelected.filter(
        filter => filter !== filterConfig.id
      );
    } else {
      SoyuzAnalytics.sendGA4Event('prefilter-select', {
        method: filterConfig.id
      });
      newFilterValue = getEnabledValue(
        filters,
        filterConfig.filter,
        filterConfig.value
      );
      filtersSelected = [...filtersSelected, filterConfig.id];
    }

    onFiltersUpdate({ [filterConfig.filter]: newFilterValue });
    setPrefiltersApplied(filtersSelected);
  };

  const handleScroll = debounce(() => {
    SoyuzAnalytics.sendGA4Event('prefilter-scroll', {});
  }, SCROLL_DEBOUNCE);

  const hidePrefilters = !mustShowPrefilters(filters, PREFILTERS_CONFIG);
  if (hidePrefilters) {
    return null;
  }

  const listToUse =
    orderByRelevanceVPexperiment.get('behaviour') === 'variant'
      ? PREFILTERS_CONFIG_ORDER_BY_RELEVANCE_VP
      : PREFILTERS_CONFIG;

  return (
    <div className="pre-filters">
      <ul className="pre-filters__list" onScroll={handleScroll}>
        {listToUse.map(filterConfig => (
          <li key={filterConfig.id} className="pre-filters__list-item">
            <IconPreFilterItem
              id={filterConfig.id}
              onChange={handlePrefilterClick(filterConfig)}
              iconName={filterConfig.iconName}
              checked={isFilterChecked(
                filters,
                filterConfig.filter,
                filterConfig.value
              )}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

PreFilters.propTypes = {
  onFiltersUpdate: PropTypes.func.isRequired
};

export default PreFilters;
