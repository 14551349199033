import PropTypes from 'prop-types';

import Currency from '@spotahome/soyuz-currency';

import FormInput from '../FormInput';

const PriceInput = ({
  currencyIsoCode,
  hasError = false,
  handleOnChange = () => {},
  value = '',
  placeholder = '',
  title = '',
  name = '',
  id = undefined
}) => {
  const isCurrencySymbolOnLeft =
    Currency.isCurrencySymbolOnLeft(currencyIsoCode);

  const currencySymbol = Currency.getCurrencySymbolForISOCode(currencyIsoCode);

  return (
    <FormInput
      title={title}
      name={name}
      id={id}
      type="number"
      pattern="[0-9]*"
      inputmode="numeric"
      onChange={handleOnChange}
      placeholder={placeholder}
      value={value}
      hasError={hasError}
      {...(isCurrencySymbolOnLeft
        ? {
            renderPrefix: () => currencySymbol
          }
        : {
            renderSuffix: () => currencySymbol
          })}
    />
  );
};

PriceInput.propTypes = {
  currencyIsoCode: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  handleOnChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string
};

export default PriceInput;
