import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { trans, getResourcePath } from '@spotahome/soyuz/client';
import {
  Modal,
  Button,
  CloseButton,
  useModalHandle
} from '@spotahome/ui-library';

import AdUrlService from '../../utils/ad-url-service';

import './ListingUnavailableModal.scss';

const ListingUnavailableModal = ({
  onCloseClick = () => {},
  onClick = null,
  isClosable = true
}) => {
  const { isModalOpen, handleModalOpen, handleModalClose } = useModalHandle();

  useEffect(() => {
    handleModalOpen();
  }, [handleModalOpen]);

  const handleClose = () => {
    if (!isClosable) {
      return;
    }
    handleModalClose();
    AdUrlService.deleteQueryParameter('show-modal');
    onCloseClick();
  };

  const handleOnClick = e => {
    if (onClick) {
      onClick(e);
      return;
    }
    handleClose('search-button');
  };

  return (
    <Modal isOpen={isModalOpen}>
      <div data-test="301Modal" className="unavailable-listing__container">
        {isClosable && (
          <CloseButton
            className="unavailable-listing__close"
            onClick={() => handleClose('close-button')}
            data-test="301ModalCloseButton"
          />
        )}
        <h2 className="unavailable-listing__title">
          {trans('search.listing-unavailable.title')}
        </h2>
        <img
          className="unavailable-listing__image"
          src={getResourcePath('/images/show-rentable-unit/301-door.svg')}
          alt={trans('search.listing-unavailable.alt-text')}
        />
        <p className="unavailable-listing__description">
          {trans('search.listing-unavailable.description')}
        </p>
        <Button
          size="large"
          onClick={handleOnClick}
          data-test="301ModalSearchButton"
        >
          {trans('search.listing-unavailable.cta')}
        </Button>
      </div>
    </Modal>
  );
};

ListingUnavailableModal.propTypes = {
  onCloseClick: PropTypes.func,
  onClick: PropTypes.func,
  isClosable: PropTypes.bool
};

export default ListingUnavailableModal;
