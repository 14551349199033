import PropTypes from 'prop-types';
import { trans, useSoyuzExperiment } from '@spotahome/soyuz/client';
import { Tag, Verified } from '@spotahome/ui-library';

import './HomecardLabel.scss';

const CHECKED = 'checked';
const OFFER = 'offer';
const PERMANENT_CONTRACT = 'permanentContract';
const BEST_CHOICE = 'bestChoice';

const chipsRenders = {
  [CHECKED]: icon => (
    <Tag
      label={trans('homecard.label.checked').toUpperCase()}
      renderLeadingIcon={() => icon}
      isSpaced={!!icon}
      isDarkMode
    />
  ),
  [OFFER]: () => null,
  [PERMANENT_CONTRACT]: () => null,
  [BEST_CHOICE]: () => null
};

const renderChip = (labelToRender, icon) => chipsRenders[labelToRender](icon);

const HomeCardLabels = () => {
  const isVerifiedIconExperiment =
    useSoyuzExperiment('CheckedIcon').get('behaviour') === 'variant';

  const icon = isVerifiedIconExperiment ? (
    <Verified color="interaction-dark" />
  ) : null;

  return renderChip(CHECKED, icon);
};

export const generateHomeCardLabels =
  ({ checked }) =>
  () =>
    checked ? <HomeCardLabels checked={checked} /> : null;

export default generateHomeCardLabels;
