import { Fragment } from 'react';
import { getPagePath, trans } from '@spotahome/soyuz/client';

import FooterSimplifiedBlockTitle from './components/FooterSimplifiedBlockTitle';
import FooterSimplifiedBlockList from './components/FooterSimplifiedBlockList';
import FooterSimplifiedLink from './components/FooterSimplifiedLink';

const LegalFooter = () => {
  const links = [
    {
      url: getPagePath('externals.privacy-policies'),
      transKey: 'footer.item.privacy_policies',
      noFollow: true
    },
    {
      url: getPagePath('landings.cookies-policy'),
      transKey: 'footer.item.cookies_policy',
      noFollow: true
    },
    {
      url: getPagePath('externals.terms-and-conditions'),
      transKey: 'footer.item.terms_and_conditions'
    }
  ];

  return (
    <Fragment>
      <FooterSimplifiedBlockTitle text={trans('footer.label.legal')} />
      <FooterSimplifiedBlockList>
        {links.map(({ transKey, url }) => (
          <FooterSimplifiedLink key={transKey} href={url}>
            {trans(transKey)}
          </FooterSimplifiedLink>
        ))}
      </FooterSimplifiedBlockList>
    </Fragment>
  );
};

export default LegalFooter;
