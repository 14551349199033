import { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './HomeCardFlipBanner.scss';

const HomeCardFlipBanner = memo(
  ({ Front, Back, className, isOneColumnView }) => {
    const [isFlipped, setFlipped] = useState(false);
    const frontCard = useRef();
    const backCard = useRef();

    useEffect(() => {
      if (backCard.current && frontCard.current) {
        frontCard.current.style.height = null;
        backCard.current.style.height = null;

        if (isOneColumnView) {
          if (backCard.current.clientHeight > frontCard.current.clientHeight) {
            frontCard.current.style.height = `${backCard.current.clientHeight}px`;
          } else {
            backCard.current.style.height = `${frontCard.current.clientHeight}px`;
          }
        }
      }
    }, [isOneColumnView]);

    const classname = classNames('home-card-banner', {
      'home-card-banner--flipped': isFlipped,
      'home-card-banner--multi-column-view': !isOneColumnView
    });
    const bannerWrapperClassName = classNames('home-card-banner__wrapper', {
      [`home-card-banner__wrapper--${className}`]: className
    });

    return (
      <div className={classname}>
        <div className={bannerWrapperClassName}>
          <div
            className="home-card-banner__side home-card-banner__side--back"
            onClick={() => setFlipped(!isFlipped)}
            role="button"
            tabIndex="0"
            ref={backCard}
          >
            <Back isOneColumnView={isOneColumnView} />
          </div>
          <div
            className="home-card-banner__side home-card-banner__side--front"
            onClick={() => setFlipped(!isFlipped)}
            role="button"
            tabIndex="0"
            ref={frontCard}
          >
            <Front isOneColumnView={isOneColumnView} />
          </div>
        </div>
      </div>
    );
  }
);

HomeCardFlipBanner.displayName = 'HomeCardFlipBanner';

HomeCardFlipBanner.propTypes = {
  Front: PropTypes.element.isRequired,
  Back: PropTypes.element.isRequired,
  className: PropTypes.string,
  isOneColumnView: PropTypes.bool.isRequired
};

HomeCardFlipBanner.defaultProps = {
  className: ''
};

export default HomeCardFlipBanner;
