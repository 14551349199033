import PropTypes from 'prop-types';

import Colors, { AVAILABLE_COLORS } from '../utils/colors';

const Verified = ({ color = 'primary', ...props }) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="6.5" cy="6.5" r="5.5" fill="white" />
      <path
        d="M5.38717 9.1692L9.8692 4.68717L9.16665 3.98462L5.38717 7.7641L3.48717 5.8641L2.78462 6.56665L5.38717 9.1692ZM6.33443 12.6666C5.45848 12.6666 4.63512 12.5004 3.86437 12.168C3.0936 11.8355 2.42314 11.3844 1.853 10.8145C1.28284 10.2446 0.831472 9.57441 0.498883 8.80398C0.166295 8.03357 0 7.21039 0 6.33443C0 5.45848 0.166222 4.63512 0.498667 3.86437C0.831111 3.0936 1.28228 2.42315 1.85217 1.853C2.42207 1.28285 3.09223 0.831473 3.86265 0.498884C4.63306 0.166295 5.45624 0 6.3322 0C7.20816 0 8.03151 0.166223 8.80227 0.498667C9.57303 0.831112 10.2435 1.28228 10.8136 1.85217C11.3838 2.42207 11.8352 3.09223 12.1678 3.86265C12.5003 4.63306 12.6666 5.45624 12.6666 6.3322C12.6666 7.20816 12.5004 8.03151 12.168 8.80227C11.8355 9.57303 11.3844 10.2435 10.8145 10.8136C10.2446 11.3838 9.57441 11.8352 8.80398 12.1678C8.03357 12.5003 7.21039 12.6666 6.33443 12.6666Z"
        fill={Colors[color]}
      />
    </svg>
  );
};

Verified.propTypes = {
  color: PropTypes.oneOf(AVAILABLE_COLORS)
};

export default Verified;
