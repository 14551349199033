/* eslint-disable import/no-named-as-default-member */
import { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { getItem, setItem } from '../../utils/localStorage';

import './HotspotsContext.scss';

const HotspotsContext = createContext({
  storeVisitedFilter: () => {},
  setHotspotVisited: () => () => {},
  isHotspotActive: () => () => false
});

export const HOTSPOT_FILTERS = {
  availabilities: 'availabilities',
  propertyType: 'propertyType',
  budget: 'budget'
};

const HOTSPOT_ORDER = [
  HOTSPOT_FILTERS.availabilities,
  HOTSPOT_FILTERS.propertyType,
  HOTSPOT_FILTERS.budget
];

const HOTSPOT_LOCAL_STORAGE = 'sah_hotspots_visited';
const HOTSPOT_FILTER_LOCAL_STORAGE = 'sah_hotspots_filter_visited';

const getStoredItems = storage => () => {
  const visitedFiltersStored = getItem(storage);

  return visitedFiltersStored ? JSON.parse(visitedFiltersStored) : [];
};

const getStoredHotspots = getStoredItems(HOTSPOT_LOCAL_STORAGE);
const getStoredVisitedFilters = getStoredItems(HOTSPOT_FILTER_LOCAL_STORAGE);

const storeItemLocalStorage = storage => item => {
  const storedItems = getStoredItems(storage)();
  setItem(storage, JSON.stringify([...storedItems, item]));
};

const storeHotspot = storeItemLocalStorage(HOTSPOT_LOCAL_STORAGE);
const storeVisitedFilter = storeItemLocalStorage(HOTSPOT_FILTER_LOCAL_STORAGE);

export const HotspotsProvider = ({ children }) => {
  const [activeHotspot, setActiveHotspot] = useState(null);

  const activateNextHotspot = lastVisited => {
    const lastVisitedIndex = HOTSPOT_ORDER.indexOf(lastVisited);
    const nextActiveHotspot = HOTSPOT_ORDER[lastVisitedIndex + 1];

    if (getStoredVisitedFilters().includes(nextActiveHotspot)) {
      activateNextHotspot(nextActiveHotspot);
    } else {
      setActiveHotspot(nextActiveHotspot);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const lastVisited = getStoredHotspots().pop();
      activateNextHotspot(lastVisited);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isHotspotActive = hotspotName => () => activeHotspot === hotspotName;

  const setHotspotVisited = hotspotName => () => {
    const hotspotIndex = HOTSPOT_ORDER.indexOf(hotspotName) + 1;

    if (isHotspotActive(hotspotName)()) {
      storeHotspot(hotspotName);
      activateNextHotspot(hotspotName);
    } else {
      storeVisitedFilter(hotspotName);
    }
  };

  return (
    <HotspotsContext.Provider
      value={{
        setHotspotVisited,
        storeVisitedFilter,
        isHotspotActive
      }}
    >
      {children}
    </HotspotsContext.Provider>
  );
};

HotspotsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

const useHotspotsContext = () => useContext(HotspotsContext);

const HotspotLed = () => <span className="hotspots-led" />;

export const useHotspotItem = hotspotName => {
  const context = useHotspotsContext();

  return {
    initializeHotspotVisited: () => context.storeVisitedFilter(hotspotName),
    setHotspotVisited: context.setHotspotVisited(hotspotName),
    isHotspotActive: context.isHotspotActive(hotspotName),
    renderHotspotLed: () =>
      context.isHotspotActive(hotspotName)() ? (
        <HotspotLed hotspotName={hotspotName} />
      ) : null
  };
};

export default {
  HotspotsProvider,
  useHotspotItem,
  HOTSPOT_FILTERS
};
