import { createInstance } from '@spotahome/soyuz-analytics';
import isEmpty from 'lodash/isEmpty';

import {
  mapListingToProduct,
  mapSearchPageHomecardToProduct,
  mapExtraRoomsToProduct,
  mapSimilarPropertyToProduct,
  mapFavoriteToProduct,
  mapExtraRoomToCd,
  mapSimilarPropertyToCd,
  mapFavoriteToCd,
  mapBookNowButtonToECommerce,
  mapBookNowButtonToProduct
} from './utils/parsers';

import {
  getContractDaysCount,
  getDailyPrice,
  getInstantBookingVariant
} from './utils/mappings';

import {
  TRACKING_PAGE_TYPES,
  LIST_E_COMMERCE,
  ECOMMERCE_STEPS
} from './constants';
import { CustomDimensionsManager } from './customDimensionsManager';

const CHECKOUT_TRACKING_STEP = 1;
const GA_IMPRESSIONS_MAX_SIZE = 50;
const GA_UNSET_VAL = '(not set)';
const GA_UNSET_NEIGHBOURHOOD_VAL = GA_UNSET_VAL;

export const EcommerceManagerFactory = ({
  sendGa4Ecommerce,
  sendInHouseAnalytics
} = {}) => {
  const instance = createInstance({ sendGa4Ecommerce, sendInHouseAnalytics });
  const cdManager = CustomDimensionsManager(instance);
  return {
    cdManager,
    flush() {
      return instance.flush();
    },
    trackSearchPageImpressions(cardList, indexedHomecards, cityId) {
      try {
        const currencyCode = isEmpty(indexedHomecards)
          ? undefined
          : indexedHomecards[Object.keys(indexedHomecards)[0]].currencyIsoCode;
        const products = Object.values(
          cardList.map((card, position) => {
            const cardData = indexedHomecards[card.id];
            return mapSearchPageHomecardToProduct(cardData, position, cityId);
          })
        );
        this.trackImpressions(products, currencyCode);
      } catch (err) {
        console.error('Failed to track search page impressions', { err });
      }
    },
    trackExtraRoomsImpressions(listings, mainListing) {
      try {
        const currencyCode = listings[0].currency.label;
        const products = listings.map((listing, position) =>
          mapExtraRoomsToProduct(listing, mainListing, position)
        );
        cdManager.clearListingCustomDimensions();
        this.trackImpressions(products, currencyCode);
      } catch (err) {
        console.error('Failed to track extra rooms impressions', { err });
      }
    },
    trackSimilarPropertiesImpressions(similarRentableUnits) {
      try {
        const currencyCode = similarRentableUnits[0].currencyIsoCode;
        const products = similarRentableUnits.map((ru, position) =>
          mapSimilarPropertyToProduct(ru, position)
        );
        cdManager.clearListingCustomDimensions();
        this.trackImpressions(products, currencyCode);
      } catch (err) {
        console.error('Failed to track similar properties impressions', {
          err
        });
      }
    },
    trackFavoritesImpressions(favorites, locale) {
      try {
        const currencyCode = favorites[0].currencyIsoCode;
        const products = favorites.map((fav, position) =>
          mapFavoriteToProduct(fav, position)
        );
        cdManager.setSearchCommonCustomDimensions({
          locale,
          pageType: TRACKING_PAGE_TYPES.FAVORITES
        });
        this.trackImpressions(products, currencyCode);
      } catch (err) {
        console.error('Failed to track favorites impressions', { err });
      }
    },
    trackImpressions(products, currencyCode) {
      try {
        instance.ecommerceManager.setCurrencyCode(currencyCode);
        instance.ecommerceManager.sendProductImpression(
          products.slice(0, GA_IMPRESSIONS_MAX_SIZE)
        );
      } catch (e) {
        console.error('Failed to track ecommerce impressions', { e });
      }
    },
    trackSearchPageHomecardClick(homecard, position, cityId) {
      try {
        const product = mapSearchPageHomecardToProduct(
          homecard,
          position,
          cityId
        );
        const currencyCode = homecard.currencyIsoCode;
        cdManager.setSearchPropertyCustomDimensions(homecard, cityId);

        this.trackProductClick(
          product,
          currencyCode,
          TRACKING_PAGE_TYPES.SEARCH_PAGE
        );
      } catch (err) {
        console.error('Failed to track search page click', { err });
      }
    },
    trackExtraRoomClick(extraRoom, mainListing, isLogged, locale, position) {
      try {
        const product = mapExtraRoomsToProduct(
          extraRoom,
          mainListing,
          position
        );
        const currencyCode = extraRoom.currency.label;
        const cdObject = mapExtraRoomToCd(
          extraRoom,
          mainListing,
          isLogged,
          locale
        );
        cdManager.setListingCustomDimensions(cdObject);

        this.trackProductClick(
          product,
          currencyCode,
          LIST_E_COMMERCE.EXTRA_ROOMS
        );
      } catch (err) {
        console.error('Failed to track extra room click', { err });
      }
    },
    trackSimilarPropertyClick(listing, isLogged, locale, position) {
      try {
        const product = mapSimilarPropertyToProduct(listing, position);
        const currencyCode = listing.currencyIsoCode;
        const cdObject = mapSimilarPropertyToCd(listing, isLogged, locale);
        cdManager.setListingCustomDimensions(cdObject);

        this.trackProductClick(
          product,
          currencyCode,
          LIST_E_COMMERCE.SIMILAR_PROPERTIES
        );
      } catch (err) {
        console.error('Failed to track similar properties click', { err });
      }
    },
    trackFavoriteClick(favorite, locale, position) {
      try {
        const product = mapFavoriteToProduct(favorite, position);
        const currencyCode = favorite.currencyIsoCode;
        const cdObject = mapFavoriteToCd(favorite, locale);
        cdManager.setSearchPropertyCustomDimensions(cdObject, favorite.city);

        this.trackProductClick(
          product,
          currencyCode,
          TRACKING_PAGE_TYPES.FAVORITES
        );
      } catch (err) {
        console.error('Failed to track favorite click', { err });
      }
    },
    trackProductClick(product, currencyCode, list) {
      try {
        instance.ecommerceManager.setCurrencyCode(currencyCode);
        instance.ecommerceManager.sendSelectedProduct(product, {
          list
        });
      } catch (e) {
        console.error('Failed to track trackProductClick', { e });
      }
    },

    trackProductDetail({ listing, locale, isLogged, moveIn, moveOut }) {
      try {
        const product = mapListingToProduct(listing);
        cdManager.setListingCustomDimensions({
          listing,
          isLogged,
          locale,
          pageType: TRACKING_PAGE_TYPES.PRODUCT_PAGE,
          moveIn,
          moveOut
        });
        instance.ecommerceManager.setCurrencyCode(
          listing.pricing.currencyLabel
        );
        instance.ecommerceManager.sendProductDetail(product);
      } catch (e) {
        console.error('Failed to track product detail', { e });
      }
    },

    trackCheckoutStep1({ pricing, listing, unavailabilities }) {
      try {
        const ecommerceObj = mapBookNowButtonToECommerce({
          pricing,
          listing,
          unavailabilities
        });
        cdManager.setBookNowButtonCustomDimensions(ecommerceObj);
        instance.ecommerceManager.setCurrencyCode(ecommerceObj.currencyCode);

        const product = mapBookNowButtonToProduct({ pricing, listing });

        instance.ecommerceManager.sendAddProductToCart(product);
        instance.ecommerceManager.sendCheckoutStep(product, {
          step: CHECKOUT_TRACKING_STEP
        });
      } catch (e) {
        console.error('Failed to track checkoutStep1', { e });
      }
    },

    trackEcommerceStep({
      listing,
      pricing,
      step,
      bookingRequest,
      hashedUserEmail,
      locator
    }) {
      try {
        const product = {
          id: listing.id,
          name: listing.type,
          brand: listing.cityId,
          category: GA_UNSET_NEIGHBOURHOOD_VAL,
          variant: getInstantBookingVariant(listing),
          price: getDailyPrice(pricing),
          quantity: getContractDaysCount(pricing)
        };
        const options = {
          id: bookingRequest ? bookingRequest.id : null,
          locator: locator || null,
          reference: bookingRequest ? bookingRequest.referenceCode : null,
          step,
          hashedUserEmail
        };
        instance.ecommerceManager.sendCheckoutStep(product, options);
      } catch (e) {
        console.log('Failed to trackEcommerceStep', { e });
      }
    },

    trackCheckoutPaymentType({ currencyCode, instrumentType }) {
      try {
        instance.ecommerceManager.setCurrencyCode(currencyCode);
        instance.ecommerceManager.sendCheckoutPaymentType({
          step: ECOMMERCE_STEPS.paymentMethod,
          instrumentType
        });
      } catch (e) {
        console.log('Failed to trackCheckoutPaymentType', { e });
      }
    }
  };
};

export default {
  EcommerceManagerFactory
};
