import PropTypes from 'prop-types';

import { HeaderContainer as HeaderContainerComponent } from '@spotahome/ui-library';

import './HeaderContainer.scss';

const HeaderContainer = ({
  renderExtraContent = () => {},
  renderLeftContent = () => {}
}) => {
  return (
    <HeaderContainerComponent
      className="header-menu-wrapper"
      color="light"
      showLogin
      links={{ changeCity: true }}
      renderExtraContent={renderExtraContent}
      renderLeftContent={renderLeftContent}
      isContactUsEnabled
    />
  );
};

HeaderContainer.propTypes = {
  renderExtraContent: PropTypes.func,
  renderLeftContent: PropTypes.func
};

export default HeaderContainer;
