import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz/client';

import AlertButton from '@spotahome/marketplace-common/src/components/AlertButton';

import './AlertsCreationReminder.scss';

const ALERTS_CREATION_IMAGE =
  'https://static.spotahome.com/images/no_results.svg';

const AlertsCreationReminder = ({ cityId }) => {
  return (
    <div className="alert-creation-reminder" data-test="alertCreationReminder">
      <img
        src={ALERTS_CREATION_IMAGE}
        alt={trans('search.alert.button.save-my-search')}
      />
      <div className="alert-creation-reminder__text">
        {trans('search.alert.creation.reminder.with.results')}
      </div>
      <div className="alert-creation-reminder__cta">
        <AlertButton
          cityId={cityId}
          buttonProps={{
            color: 'interaction'
          }}
          dataTest="alert-creation-reminder"
        />
      </div>
    </div>
  );
};
AlertsCreationReminder.propTypes = {
  cityId: PropTypes.string.isRequired
};

export default AlertsCreationReminder;
