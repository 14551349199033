import PropTypes from 'prop-types';

import { Button, FilterUtils } from '@spotahome/ui-library';
import { trans } from '@spotahome/soyuz/client';
import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import SortBy from '../SortBy';

import { generateTitleElems, generateTitleElemsSimplified } from './utils';

import './SearchTitle.scss';

const SearchTitle = ({
  totalResults = 0,
  onSortChange = () => {},
  cityName,
  onClearFilters = () => {}
}) => {
  const { filters } = useFiltersContext();
  const topology = filters['topology[]'];
  const handleClearFilters = () => {
    onClearFilters();
  };

  const shouldShowClearFiltersButton = () => {
    const activeFiltersCount = FilterUtils.getActiveFiltersCount(filters);
    return activeFiltersCount > 0 && totalResults !== 0;
  };

  /**
   * We need filtersNoPropertyType to show pattern h1 for SEO.
   * We will pass the empty type array to mock the default filter.
   * We are renaming move-in and move-out to the expected from the function
   */
  const {
    'type[]': types,
    'move-in': moveIn,
    'move-out': moveOut,
    ...filtersNoPropertyType
  } = filters;

  return (
    <div className="search-title">
      {FilterUtils.isDefaultFilterFromUrl({
        ...filtersNoPropertyType,
        moveIn,
        moveOut,
        'type[]': []
      }) && types.length <= 1 ? (
        <h1
          className="search-title__title"
          data-test="search-title"
          dangerouslySetInnerHTML={{
            __html: generateTitleElemsSimplified(types, cityName, totalResults)
          }}
        />
      ) : (
        <h1 className="search-title__title" data-test="search-title">
          <strong data-test="search-title-total-count">{totalResults}</strong>{' '}
          {generateTitleElems(types, cityName, topology)}
        </h1>
      )}

      <div className="search-title__summary">
        {shouldShowClearFiltersButton() ? (
          <Button
            color="select"
            alt
            size="small"
            onClick={handleClearFilters}
            className="search-title__clear"
            data-test="title-filters-summary-clear-button"
          >
            {trans('search.filters.clear.cta')}
          </Button>
        ) : null}
        <SortBy
          handleSortByChange={onSortChange}
          orderBy={filters.sortBy}
          borderless
        />
      </div>
    </div>
  );
};

SearchTitle.propTypes = {
  totalResults: PropTypes.number,
  onSortChange: PropTypes.func,
  cityName: PropTypes.string.isRequired,
  onClearFilters: PropTypes.func
};

export default SearchTitle;
