import PropTypes from 'prop-types';

import HomeCardFlipBanner from '../../components/HomeCardFlipBanner';

import SelectBannerFront from './SelectBannerFront';
import SelectBannerBack from './SelectBannerBack';

const SelectBanner = ({ isOneColumnView }) => (
  <HomeCardFlipBanner
    Front={SelectBannerFront}
    Back={SelectBannerBack}
    className="select"
    isOneColumnView={isOneColumnView}
  />
);

SelectBanner.propTypes = {
  isOneColumnView: PropTypes.bool.isRequired
};

export default SelectBanner;
