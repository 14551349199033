import styles from '../FooterSimplified.module.scss';

import Separator from '../../Separator';

const FooterSimplifiedSeparator = () => (
  <div className={styles.footer__separator}>
    <Separator theme="medium" />
  </div>
);

export default FooterSimplifiedSeparator;
