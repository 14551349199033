import { memo } from 'react';
import classNames from 'classnames';

import FooterBottom from '../FooterBottom';

import SocialFooter from '../FooterContainer/SocialFooter';

import GlobeLanguageSwitcher from '../GlobeLanguageSwitcher/GlobeLanguageSwitcher';

import FooterSimplifiedSection from './components/FooterSimplifiedSection';
import FooterSimplifiedSeparator from './components/FooterSimplifiedSeparator';

import SpotahomeFooter from './SpotahomeFooter';
import LegalFooter from './LegalFooter';
import SupportFooter from './SupportFooter';
import AppFooter from './AppFooter';

import FooterSimplifiedBlock from './components/FooterSimplifiedBlock';

import styles from './FooterSimplified.module.scss';

const FooterSimplified = () => (
  <footer>
    <section className={classNames(styles.footer, styles['footer--beige'])}>
      <FooterSimplifiedSection>
        <SpotahomeFooter />
        <FooterSimplifiedBlock>
          <LegalFooter />
        </FooterSimplifiedBlock>
        <FooterSimplifiedBlock>
          <SupportFooter />
          <GlobeLanguageSwitcher className={styles.footer__app} />
        </FooterSimplifiedBlock>
        <FooterSimplifiedBlock className={styles.footer__tablet}>
          <AppFooter />
          <SocialFooter />
        </FooterSimplifiedBlock>
      </FooterSimplifiedSection>
    </section>
    <section className={styles.footer__bottom}>
      <FooterSimplifiedSeparator />
      <FooterSimplifiedSection>
        <FooterBottom />
      </FooterSimplifiedSection>
    </section>
  </footer>
);

export default memo(FooterSimplified);
