import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Mediator } from '@spotahome/soyuz-mediator';
import { trans } from '@spotahome/soyuz/client';
import Metrics from '@spotahome/soyuz-tracking';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { useCookie, useUserInfoContext } from '..';
import { addFavorite, removeFavorite } from '../mutations/favorites';
import { COMMON_LOGIN_MODAL } from '../components/utils/constants';
import getGqlClient from '../clients/graphql/marketplace';

const FavoriteContext = createContext({
  isFavorite: false,
  toggleFavorite: () => {}
});

export const FavoriteProvider = ({
  isFavorite,
  listingId,
  cityId,
  propertyType,
  price,
  favorites,
  favoritesCount,
  children
}) => {
  return (
    <FavoriteContext.Provider
      value={{
        isFavorite,
        listingId,
        favorites,
        cityId,
        propertyType,
        price,
        favoritesCount
      }}
    >
      {children}
    </FavoriteContext.Provider>
  );
};

FavoriteProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isFavorite: PropTypes.bool.isRequired,
  listingId: PropTypes.string.isRequired,
  cityId: PropTypes.string.isRequired,
  propertyType: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  favorites: PropTypes.arrayOf(PropTypes.number).isRequired,
  favoritesCount: PropTypes.number.isRequired
};

export const useFavoriteConsumer = () => useContext(FavoriteContext);

const noOp = () => {};

const COOKIE_NAME = 'sah-guest-used-favorite';
const COOKIE_VALUE = 1;
const COOKIE_DURATION_30_MINS = 1800;

export const useFavorite = ({
  onToggleFavorite = noOp,
  position = null,
  pageType, // `search` or `product_page`
  section // 'listing', 'other_rooms', 'similar_properties'
}) => {
  const {
    isFavorite: isInitialFavorite,
    listingId,
    cityId,
    propertyType,
    price,
    favoritesCount: initialFavoritesCount
  } = useFavoriteConsumer();

  const { isUserLogged } = useUserInfoContext();
  const { getCookie, setCookie } = useCookie();
  const [isFavorite, setIsFavorite] = useState(isInitialFavorite);
  const [favoritesCount, setFavoritesCount] = useState(initialFavoritesCount);

  const markFavoriteSilently = async () => {
    if (!listingId) {
      console.error('Can not toggle a favorite without an listingId');
      return;
    }
    try {
      await addFavorite(getGqlClient())(listingId);
    } catch (error) {
      console.error(error);
    }
  };

  const markAsFavorite = async (favoriteId, isLogged) => {
    await addFavorite(getGqlClient())(favoriteId);
    if (!isLogged && !getCookie(COOKIE_NAME)) {
      setCookie(COOKIE_NAME, COOKIE_VALUE, {
        maxAge: COOKIE_DURATION_30_MINS
      });
      Mediator.publish(COMMON_LOGIN_MODAL, {
        initialAction: 'signup',
        renderTitle: action => trans(`${action}.modal.title`),
        renderSubtitle: action => trans(`${action}.modal.subtitle`),
        events: {
          open: {
            eventCategory: 'signup/login modal',
            eventAction: 'FavoriteClaim',
            eventLabel: 'open'
          },
          close: {
            eventCategory: 'signup/login modal',
            eventAction: 'FavoriteClaim',
            eventLabel: 'close'
          }
        }
      });
    }

    SoyuzAnalytics.sendGA4Event('add_to_wishlist', {
      items: [
        {
          item_id: favoriteId,
          item_category: cityId,
          item_category2: propertyType,
          price,
          quantity: 1,
          item_list_id: section,
          index: position ?? 0
        }
      ]
    });

    Metrics.actions.sendActionEvent(Metrics.actions.SAVE_FAVORITE, {
      listingId: `${listingId}`,
      pageType,
      section: section || undefined
    });
  };

  const unmarkAsFavorite = async () => {
    await removeFavorite(getGqlClient())(listingId);
  };

  const toggleFavorite = async () => {
    if (!listingId) {
      console.error('Can not toggle a favorite without an listingId');
      return;
    }
    const newIsFavorite = !isFavorite;
    setIsFavorite(newIsFavorite);
    setFavoritesCount(newIsFavorite ? favoritesCount + 1 : favoritesCount - 1);

    try {
      const action = newIsFavorite ? markAsFavorite : unmarkAsFavorite;
      await action(listingId, isUserLogged);
      onToggleFavorite(listingId, newIsFavorite);
    } catch (error) {
      console.error(error);
      setIsFavorite(!newIsFavorite);
    }
  };

  return {
    isFavorite,
    favoritesCount,
    toggleFavorite,
    markFavoriteSilently
  };
};

export default {
  FavoriteProvider,
  useFavoriteConsumer,
  useFavorite
};
