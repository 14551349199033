import { getPagePath, trans } from '@spotahome/soyuz/client';

import HelpCenterButton from '../FooterContainer/HelpCenterButton';

import FooterSimplifiedBlockTitle from './components/FooterSimplifiedBlockTitle';
import FooterSimplifiedLink from './components/FooterSimplifiedLink';

const SupportFooter = () => (
  <>
    <FooterSimplifiedBlockTitle text={trans('footer.label.support')} />
    <HelpCenterButton
      color="exposed"
      href={getPagePath('externals.help-center')}
    />
    <FooterSimplifiedLink
      key="footer.item.contact_support"
      href={getPagePath('contact-us')}
    >
      {trans('footer.item.contact_support')}
    </FooterSimplifiedLink>
  </>
);

export default SupportFooter;
